import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarBookmarkComponent } from 'app/layout/components/navbar/navbar-bookmark/navbar-bookmark.component';
import { NavbarSearchComponent } from 'app/layout/components/navbar/navbar-search/navbar-search.component';
import { NavbarCartComponent } from 'app/layout/components/navbar/navbar-cart/navbar-cart.component';
import { NavbarNotificationComponent } from 'app/layout/components/navbar/navbar-notification/navbar-notification.component';


import { IgxDateRangePickerModule } from "igniteui-angular";
import { Daterangepicker } from "ng2-daterangepicker";
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnrichedAcademyNumberFormatterModule } from 'app/pipes/number-formatter/number-formatter.module';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarSearchComponent,
    NavbarBookmarkComponent,
    NavbarCartComponent,
    NavbarNotificationComponent
  ],
  imports: [RouterModule, NgbModule, CommonModule, CoreCommonModule, PerfectScrollbarModule, CoreTouchspinModule,
    IgxDateRangePickerModule,Daterangepicker, NgSelectModule],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {}
