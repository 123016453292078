import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable()
export class DashboardHttpService {

  private baseUrl = environment.apiUrl;
  private forntendBaseUrl = environment.FRONTEND_BASE_URI;
  AUTH_TOKEN = environment.auth_token;
  language = localStorage.getItem('language') ?? 'en';
  headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };

  constructor(private httpClient: HttpClient, private toastr: ToastrService, private deviceService: DeviceDetectorService, private authService: AuthenticationService,) {
  }

  get(url: string, params?: any): Observable<any> {
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.get(this.baseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  post(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  frontendPost(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.forntendBaseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }


  private errorHandler(response: any) {
    // this.toastr.error('', environment.serverErrorMessage);
    console.log(response,environment.serverErrorMessage)
    const error = response.error;
    let message;
    if (error) {

      const keys = Object.keys(error);
      const key = keys[0];
      message = error[key];
      if (response.status === 401) {
        // auth token delete
        // redirect login page
      }
      if (error[key] instanceof Array) {
        message = error[key][0];
      }
      if (key === 'isTrusted') {
        // this will occur when not connected to internet
      } else {
        message = key + ' : ' + message;
      }
    } else {
      message = response;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error });
  }

  private getAuthHeader(): { [header: string]: string | string[]; } {
    return {
      Authorization: `Bearer ${localStorage.getItem(this.AUTH_TOKEN)}`
    };
  }


}
